@import './consts.scss';

.datasheet {
  margin-top: $space-2;
}

.resultCardHint {
  display: grid;
  place-content: center;
  padding: 1rem;
  min-height: 870px;
}
