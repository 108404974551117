@use '@angular/material' as mat;
@import './consts.scss';
@include mat.all-component-typographies();
@include mat.core();

* {
  letter-spacing: normal;
}

//
// Theme
//

$sick-mat-light-blue: (
  50: hsl(201, 100%, 20%),
  100: hsl(201, 100%, 23%),
  200: hsl(201, 100%, 26%),
  300: hsl(201, 100%, 29%),
  400: hsl(201, 100%, 32%),
  500: hsl(201, 100%, 35%),
  600: hsl(201, 100%, 38%),
  700: hsl(201, 100%, 41%),
  800: hsl(201, 100%, 44%),
  900: hsl(201, 100%, 46%),
  A100: hsl(201, 100%, 47%),
  A200: hsl(201, 100%, 48%),
  A400: hsl(201, 100%, 49%),
  A700: hsl(201, 100%, 50%),
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: white
  )
);

$sick-mat-orange: (
  50: hsl(0, 0%, 1%),
  100: hsl(36, 100%, 23%),
  200: hsl(36, 100%, 26%),
  300: hsl(36, 100%, 29%),
  400: hsl(36, 100%, 32%),
  500: hsl(36, 100%, 35%),
  600: hsl(36, 100%, 38%),
  700: hsl(36, 100%, 41%),
  800: hsl(36, 100%, 44%),
  900: hsl(36, 100%, 46%),
  A100: hsl(36, 100%, 47%),
  A200: hsl(36, 100%, 48%),
  A400: hsl(36, 100%, 49%),
  A700: #ff9900,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: white
  )
);

$primary: mat.define-palette($sick-mat-light-blue);
$accent: mat.define-palette($sick-mat-orange, A100, A400, A700);
$theme: mat.define-light-theme($primary, $accent);

//
// Typography
//

$custom-typography: mat.define-typography-config(
  $font-family: 'Open Sans',
  $headline-5: mat.define-typography-level(40px, 48px, 400),
  $headline-6: mat.define-typography-level(25px, 44px, 400),
  // $body-2: mat-typography-level(12px, 14px, 500),
);

// Override typography for all Angular Material, including mat-base-typography and all components.
@include mat.all-component-typographies($custom-typography);

//
// Change text color to sick gray
// See https://stackoverflow.com/questions/43438305/how-to-change-font-color-of-primary-palette-in-angular-material2/43457066
//
@function my-mat-light-theme-foreground($color) {
  @return (
    base: $color,
    divider: rgba(black, 0.12),
    dividers: rgba(black, 0.12),
    disabled: rgba($color, 0.38),
    disabled-button: rgba($color, 0.38),
    disabled-text: rgba($color, 0.38),
    hint-text: rgba($color, 0.38),
    secondary-text: rgba($color, 0.54),
    icon: rgba($color, 0.54),
    icons: rgba($color, 0.54),
    text: rgba($color, 0.87),
    slider-off: rgba($color, 0.26),
    slider-off-active: rgba($color, 0.38)
  );
}
$sick-foreground: my-mat-light-theme-foreground($color-text);
$da-app-theme-custom: map-merge(
  $theme,
  (
    foreground: $sick-foreground
  )
);
@include mat.all-component-themes($da-app-theme-custom);

body {
  color: $color-text;
}

.mat-mdc-card-title {
  font-size: 40px !important;
}

//
// Changes the border radius in all
// input field
//
.mdc-notched-outline__leading,
.mdc-notched-outline__notch,
.mdc-notched-outline__trailing {
  &:not(.mdc-text-field--disabled) {
    border-color: $color-border !important;
  }
  border-radius: 0 !important;
}

.mdc-floating-label {
  color: rgba(50, 60, 67, 0.6) !important;
  font-size: 14px !important;
}

.mat-mdc-select-value,
.mdc-list-item__primary-text,
.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
  font-size: 14px !important;
}

.mat-mdc-form-field-icon-suffix {
  margin-right: 1rem;
}

input[disabled='disabled'] {
  color: darkgray !important;
}

.cdk-overlay-pane {
  margin-top: -20px !important;
}

//
// Buttons
//
button {
  white-space: nowrap;
}

.mat-mdc-unelevated-button,
.mat-mdc-outlined-button {
  border-radius: 0 !important;
  padding: 19px !important;
  height: 56px !important;

  mat-icon {
    height: 24px !important;
    width: 24px !important;
    font-size: 24px !important;
  }

  &[disabled='disabled'] {
    border: 1px solid #7dc3e8 !important;
    // color: darkgray !important;
    color: white !important;
    background-color: #7dc3e8 !important;
    cursor: not-allowed !important;
  }
}

.mat-secondary,
.mat-mdc-outlined-button {
  border: 1px solid $color-primary !important;
  color: $color-primary !important;
}

//
// Checkbox
//
.mat-mdc-form-field-type-checkbox {
  .mat-mdc-text-field-wrapper.mdc-text-field {
    padding: 0;
    background-color: transparent;
  }
}

.mdc-line-ripple {
  display: none;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.mdc-checkbox__checkmark {
  color: white !important;
}

//
// Expansion Panel
//

.mat-expansion-panel-header {
  .mat-content {
    transition: color 0.25s, font-size 0.25s;
  }
  padding: 0 !important;
}

.mat-expansion-panel-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.mat-expansion-indicator {
  padding: 5px !important;
  padding-right: 16px !important;
  padding-left: 16px !important;
  display: grid;
  place-items: center;
}

h2 {
  font-size: 1.3rem !important;
}

.mat-expanded {
  mat-expansion-panel-header {
    // display: none;
    .mat-content {
      // font-size: 1.3rem;
      color: $color-primary;
    }
  }
}

.mat-expanded mat-expansion-panel-header .mat-content {
  overflow: unset;
}

.mat-expansion-panel:not([class*='mat-elevation-z']) {
  /* box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); */
  box-shadow: none;
}

.panel-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: 500;
  font-size: 1.2rem;
}

.panel-header.invalid {
  color: red;
}

.number {
  padding: 4px;
  text-align: center;
  font-size: 16px;
  line-height: 12px;
  border: 2px solid;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-right: 1rem;
  font-weight: 600;
}

//
// Table
//

.mat-mdc-row {
  align-items: flex-start;
}

.mdc-data-table__header-cell {
  font-size: 12px !important;
  color: rgba(50, 60, 67, 0.54) !important;
}

.mat-mdc-row:hover {
  cursor: pointer;
  color: white;
  z-index: 100;
  background-color: hsl(201, 100%, 99%) !important;
  outline: 2px solid $color-primary;
  border-radius: 3px;
}

//
// Paginator
//

.mat-mdc-paginator-page-size {
  .mdc-notched-outline__leading,
  .mdc-notched-outline__trailing {
    border: none;
  }

  .mat-mdc-select-value-text {
    color: rgba(50, 60, 67, 0.87);
    font-size: 12px;
  }
}

//
// Snack Bar
//

.mat-mdc-snack-bar-container {
  margin-top: 28px !important;

  .mdc-snackbar__surface {
    background-color: white !important;
  }

  .mat-mdc-snack-bar-label {
    color: black !important;
  }
}

//
// Dialog
//

.mat-mdc-dialog-title.mdc-dialog__title {
  font-size: 40px !important;
  text-align: center !important;
  font-weight: 400 !important;

  margin-top: 2rem;
}

.mat-mdc-dialog-content.mdc-dialog__content {
  padding-top: 20px !important;
}

.mat-mdc-dialog-actions.mdc-dialog__actions {
  padding: 24px !important;
  justify-content: flex-end !important;
}

//
// Tabs
//

.mdc-tab {
  min-width: 25% !important;
}

.mdc-tab--active {
  background-color: white;

  .mdc-tab__text-label {
    color: $color-primary !important;
  }
}
.mdc-tab__text-label {
  color: white !important;
}

.mdc-tab__text-label {
  transition: none !important;
}

.mdc-tab-indicator {
  display: none !important;
}
