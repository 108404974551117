/* You can add global styles to this file, and also import other style files */
@import './assets/scss/consts.scss';
@import './assets/scss/mt-theme.scss';
@import './assets/scss/grid.scss';
@import './assets/scss/page.scss';
@import './assets/scss/global';

html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

// The bar on material tabs. Default is 2px;
.mat-ink-bar {
  height: 3px !important;
}
