@import './consts.scss';

// Global styles

.bold {
  font-weight: bold;
  color: #697987;
}

.italic {
  font-style: italic;
}

h1 {
  font-weight: 100;
  font-size: 40px;
  line-height: 48px;
}

h2,
h3 {
  font-size: 25px;
  color: $color-primary;
}

h3 {
  font-size: 16px;
}

.button-row {
  display: grid;
  grid-auto-flow: column;
  column-gap: 1rem;
}

.error {
  color: $color-error;
}

// Pages

.center {
  display: grid;
  place-items: center;
}

.mat-mdc-outlined-button,
.mat-mdc-button {
  border-radius: 0;
}

//
// Tabs
//

.mat-mdc-tab-header {
  border-bottom: none;
}

hr {
  border: 1px solid #eee;
  margin: 2rem 0;
}

a {
  text-decoration: none;
  color: $color-primary;
}

//
// Counter
//

.content {
  counter-reset: section;
}

.counter::before {
  counter-increment: section;
  content: counter(section);
}

//
// Label Group
//
.label {
  display: flex;
  flex-direction: column;
  min-height: 72px;
  .label-header {
    font-size: 0.8rem;
    color: $color-primary;
  }

  .label-text {
    color: rgba(50, 60, 67, 0.87);
    // font-size: 1rem;
  }
}

//
// Front Page Table
//
.front-page-table {
  .mat-column-actions {
    flex-grow: 0.5;
  }

  .mat-mdc-cell {
    flex-direction: column;
    align-items: flex-start;
  }

  .secondary-line {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 0.7rem;
  }

  .nameCell {
    display: flex;
    flex-direction: column;
    align-items: baseline;

    .userList {
      font-size: 0.7rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      text-align: left;

      .user {
        margin-bottom: 0;
      }
    }
  }

  .header-align-right {
    justify-content: flex-end;
  }

  .cell-actions {
    display: flex;
    flex-direction: column;

    * {
      align-self: flex-end;
      font-size: 0.8rem;
    }

    :nth-child(1) {
      font-size: 0.7rem;
      color: #b4b4b4;
    }

    .actions {
      display: flex;
      align-items: flex-start;
      margin-top: 0.3rem;

      button {
        padding: unset !important;
        border: none;
        min-width: unset;
        line-height: unset;
        height: 40px !important;
        width: 40px !important;

        --mat-mdc-button-persistent-ripple-color: none;
        --mat-mdc-button-ripple-color: none;

        mat-icon {
          font-size: 1.2rem;
          color: #6b6c6dde;
        }

        background-color: transparent;
      }
    }
  }
}

.front-page-search-input {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 10px;
  padding-top: 10px;
  margin-bottom: 0;
}

//
// Misc
//
.justify-content-center {
  justify-content: center;
}
