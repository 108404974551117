.row {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-auto-rows: minmax(0, 1fr);
  column-gap: 1rem;
  row-gap: 0rem;

  // margin-bottom: 1rem;
  min-height: 58px;

  align-items: center;

  button {
    width: 100%;
    margin: 0 0 calc(1rem + 2px);
  }

  button.no-margin-bottom {
    margin-bottom: 0;
  }

  &.no-margin-bottom {
    margin-bottom: 0rem;
  }
}

.col-1 {
  grid-column: 1 / 2;
}
.col-2 {
  grid-column: 2 / 3;
}
.col-3 {
  grid-column: 3 / 4;
}
.col-4 {
  grid-column: 4 / 5;
}
.col-1-to-2 {
  grid-column: 1 / 3;
}
.col-1-to-3 {
  grid-column: 1 / 4;
}
.col-1-to-4 {
  grid-column: 1 / 5;
}
.col-2 {
  grid-column: 2;
}
.col-2-to-3 {
  grid-column: 2 / 4;
}
.col-2-to-4 {
  grid-column: 2 / 5;
}
.col-3-to-4 {
  grid-column: 3 / 5;
}

// For larger than 4x grids
.col-5 {
  grid-column: 5 / 6;
}
.col-6 {
  grid-column: 6 / 7;
}

.hidden {
  display: none; // To hide form input fields
}
