$max-width: 800px;

// Colors
$color-primary: #007cc1;
$color-secondary: hsl(36, 100%, 48%);
$color-green: #99c24d;
$color-error: red;
$color-text: #323c43;

$color-border: #b1b8bc; // The input border color

// Background colors
$background-color-1: #f0f3f4;
$background-color-dark: #323c43; //hsla(207, 20%, 59%, 5);

// Font-Sizes
$font-size-small: 0.8rem;
$font-size-medium: 0.9rem;
$font-size-large: 1.1rem;
$font-size-larger: 1.2rem;
$font-size-extra-large: 1.5rem;

// Spacing
$space-default: 16px;
$space-1: 0.5rem;
$space-2: 0.8rem;
$space-3: 1rem;
$space-4: 1.2rem;
$space-5: 1.4rem;
$space-6: 1.6rem;
